import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby'
//Elements 
import QuoteSlider from '../../elements/QuoteSlider';

import Para from '../../elements/Paragraph';
import AnimatedHeading from '../../elements/AnimatedHeading';
import { BannerHeading } from '../../static/css/style.js';
import ReadMore from '../../elements/ReadMore';
import ReadMoreNoLink from '../../elements/ReadMoreNoLink';
import Title from '../../elements/Title';
import { ContentBlock, AnimatedHeadingContainer, Verse } from '../../static/css/style'
import CTA from '../../elements/CTA'
import Slider from '../../elements/Slider'

const VennWrap = styled.div`
    width:100%;
    height:auto;
    display:flex;
    padding:100px 0 100px 0;
    @media screen and (max-width: 991px) {
        margin-top:10vh;
        flex-direction:column;
    }
`;
const VennTextContainer = styled.div`
    padding-top:0%;
    width: 40%;
    margin-left: 5%;
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    flex-direction:column;
    @media screen and (max-width: 991px) {
        width:100%;
        margin-left: 0%;
        margin-top:40px;
    }
    @media screen and (max-width: 479px) {
        & p{
            margin-bottom:1.45em;
        }
    }
`;
const VennContainer = styled.div`
    width:50%;
    position:relative;
    min-height:400px;
    @media screen and (max-width: 991px) {
        width:100%;
        display:flex;
        justify-content:center;
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        min-height:245px;
    }
`;
const AnimatedHeadingWhatWrap = styled.div`
    display:flex; 
    align-items:flex-start;
    justify-content:flex-start;
    width:55%;
    margin-right:auto;
    @media screen and (max-width: 991px) {
        width:100%;
    }
`;
const AnimatedHeadingWhat = styled.div`
    position:relative;
    overflow:hidden;
    width:100%;
`;
const SynergyContainer = styled.div`
    display:flex; 
    align-items:flex-start;
    justify-content:flex-start;
    width:60%;
    margin-left:auto;
    padding:100px 0 100px 0;
    @media screen and (max-width: 991px) {
        width:80%;
    }
`;
const Practice = () => {
    const [hoverState, setHoverState] = useState(false);
    const closeVenn = () => {
        setHoverState(hoverState => false);
    };
    const openVenn = () => {
        setHoverState(hoverState => true);
    };

    return (
        <div className="bodyBlack">
            <ContentBlock>
                {/* <QuoteSlider></QuoteSlider> */}
                <AnimatedHeadingContainer >
                    <BannerHeading>
                        <span className="light">we design better</span><br />
                        <span className="light">ways to</span>
                        <div className="rw-words rw-words-1">
                            <span>experience</span>
                            <span>showcase</span>
                            <span>narrate</span>
                        </div>
                        <br />
                        <span className="light">your</span>
                        <div className="rw-words rw-words-1">
                            <span>brand</span>
                            <span>product</span>
                            <span>stories</span>
                        </div>
                    </BannerHeading>
                </AnimatedHeadingContainer>
                <SynergyContainer>
                    <Para LineHeight="1.56" top="0%" wfm="true" color="white" align="right" left="auto">Finding synergy between the two questions that truly matter enables us to chart the path for successful brand outcomes. Looking at our challenge inside-out and outside-in gives us the parallelism in thought integral to aligning ourselves with an organisation’s objectives.</Para>
                </SynergyContainer>
                <AnimatedHeadingWhatWrap>
                    <AnimatedHeadingWhat>
                        <BannerHeading paddingTop={true} width="100%" >
                            <span className="light">what does your brand </span>
                            <span className="light">mean to</span>
                            <div className="rw-words rw-words-2 blue">
                                <span>you?</span>
                                <span>others?</span>
                            </div>
                        </BannerHeading>
                    </AnimatedHeadingWhat>
                </AnimatedHeadingWhatWrap>

                {/* <BannerHeading hovered={true}>what does your brand<br/> 
                mean to <AnimatedHeading content1="you?" content2="us?"/>
            </BannerHeading> */}

                <Para LineHeight="1.7" marginBottom="13%" color="white">
                    In our &nbsp;
                    <Verse to="/practice/" width="100%">
                        practice and methodology
                        <div className="verseBorder" />
                    </Verse>, we try to answer these two questions through the synthesis of...
                </Para>
                {/* <BrandComponent></BrandComponent> */}
                <VennWrap >
                    <VennContainer onMouseEnter={openVenn} onMouseLeave={closeVenn}>
                        <div id="circle-container" >
                            <div id="outer1" className={hoverState ? "outer move" : "outer"}>
                                <Title color="#fff" size="xxs" width="70%;" align="left" weight="bold" opacityTransition={hoverState} transform="scale(0.5)">brand</Title>
                            </div>
                            <div id="outer2" className={hoverState ? "outer move" : "outer"} >
                                <Title color="#fff" size="xxs" width="37%;" align="right" weight="bold" opacityTransition={hoverState} transform="scale(0.5)">business</Title>
                            </div>
                            <div id="outer3" className={hoverState ? "outer move" : "outer"} >
                                <Title color="#fff" size="xxs" width="40%;" align="center" weight="bold" opacityTransition={hoverState} transform="scale(0.5)">behaviour</Title>
                            </div>
                            <div id="midaw1" className="mwrap" >
                                <div id="midaw2" className="mwrap2">
                                    <div id="mida" className="content mid"></div>
                                </div>
                            </div>
                            <div id="midbw1" className="mwrap">
                                <div id="midbw2" className="mwrap2">
                                    <div id="midb" className="content mid"></div>
                                </div>
                            </div>
                            <div id="midcw1" className="mwrap">
                                <div id="midcw2" className="mwrap2">
                                    <div id="midc" className="content mid"></div>
                                </div>
                            </div>
                            <div className={hoverState ? "innerw show" : "innerw"}>
                                <div className="innerw2">
                                    <div className="innerw3">
                                        <div className={hoverState ? "inner show" : "inner"}>
                                            <Title color="#fff" size="xxs" weight="bold" opacityTransition={hoverState}>experience</Title>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={hoverState ? "abs hide" : "abs"}>
                                <Title size="md" weight="bold" color="#4ab9e9" opacity="100">strategy</Title>
                            </div>
                        </div>
                    </VennContainer>
                    <VennTextContainer>
                        <Para LineHeight="1.56" align="left" color="#fff">Each person your brand interacts with, elicits a response to it. We must mindfully nurture every such touchpoint into a potential <ReadMore link="/journal/moments-that-matter" display="inline-block" align="left" size="inherit" bottom="0px" color="#fff">“moment that matters”</ReadMore> - this is the crux of brand building. We have learnt that brands are not built in silos, they are the result of multidimensional tactility in thought. When you think things through, it shows, and it flows.</Para>
                        <Para LineHeight="1.56" align="left" color="#fff">We’re here to dive into the deep end with you, let’s make it an Olympic double twist single tuck somersault for Gold.</Para>
                    </VennTextContainer>
                </VennWrap>
                <ReadMore link="/contact" size="md" color="#fff" >Let's Talk</ReadMore>
                {/* <Title size="xl" weight="bold" lineHeight="100%" top="7%" bottom="7%">featured....</Title>
                <Slider restrict="true"></Slider> */}
            </ContentBlock>
        </div>

    );
};
export default Practice;