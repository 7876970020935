import React, { useState } from 'react';
import styled, { css } from 'styled-components';
const CTAbtn = styled.button`
    padding: 1em 2em;
    color: #000;
    border-radius: 10px;
    border: 1px solid #000;
    font-size:17px;
    font-family: 'Montserrat', sans-serif;
    background:transparent;
    transition:0.2s all ease;
    &:hover{
        background:#4ab9e9;
        color:#fff;
        border:1px solid #4ab9e9;
    }   

`;
const BtnContainter = styled.div`
    text-align:center;
    width:100%;
    padding: 2em 0 1em 0;
`;
const CTA = (props) => {
    return (
        <BtnContainter>
            <CTAbtn>
                {props.children}
            </CTAbtn>
        </BtnContainter>
    );
};

export default CTA;