import React,{ useState } from 'react';
import styled,{ css } from 'styled-components'

import Title from '../elements/Title'
import Para from '../elements/Paragraph'

const QuoteSlideWrap = styled.div`
    position:relative;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    justify-content:flex-end;
    -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
    align-content:flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-self:flex-start;
    -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
    align-items:flex-start;
    -webkit-align-items: flex-start;
  -ms-flex-align: start;
  height:60vh;
    width:100%;
    @media screen and (min-width: 1920px) {
      height: 60vh;
    }
    @media screen and (max-width: 729px) {
      height: 100vh;
    }
    @media screen and (max-width: 479px) {
      height: 75vh;
    }
`;
const QuoteContainer = styled.div`
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    flex-direction:row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    justify-content:flex-start;
    -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    transition:0.3s all ease;
    -webkit-transition:0.3s all ease;
    @media screen and (max-width: 479px) {
        flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      align-items: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    justify-content:flex-end;
    -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
    }
`;
const TitleContainer = styled.div`
    width:80%;
    @media screen and (max-width: 479px) {
      width:100%;
    }
`;

const Slide = (props) => {
    return (        
        <QuoteSlideWrap>
            <QuoteContainer>
              <TitleContainer>
                <Title size="lg" lineHeight="1.5" >“The customer rarely buys what the business thinks it sells him”</Title> 
              </TitleContainer>                  
                <Para color="#191919" fontWeight="bold" width="auto">~ Peter Drucker</Para>
            </QuoteContainer>            
        </QuoteSlideWrap>
    );
};

export default Slide;