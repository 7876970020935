import React,{ useState } from 'react';
import styled, { css } from 'styled-components'
const TextOne = styled.span`
    transition:all 0.3s linear;
    -webkit-transition:all 0.3s linear;
    opacity:1;
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index:10;   
    width:100%;
    ${props => props.clicked && css`
        opacity: 0;
  `}
`;
const TextTwo = styled.span`
    transition:all 0.4s linear;
    -webkit-transition:all 0.4s linear;
    width: 0%;
    margin:auto;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    justify-content: flex-start;
    -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
    white-space:nowrap;
    left: 0%;
    top: 0%;    
    bottom: 0%;
    right:${props => props.right || '0%'};
    height: auto;
    position:absolute;
    opacity: 0;
    ${props => props.clicked && css`
        width:100%;
        opacity: 1; 
  `}
`;
const AnimatedHeading = (props) => {
    const [textState, setTextState] = useState(false);
    const toggleText = () =>{
        setTextState(textState => !textState);        
    };
    return (            
            <span style={{"position":"relative","width":"100%"}}>
                <TextOne clicked={textState} onMouseEnter={toggleText} onMouseLeave={toggleText} onClick={toggleText}>{props.content1}</TextOne>
                <TextTwo right={props.right} clicked={textState}>{props.content2}</TextTwo>
            </span>
    );
};

export default AnimatedHeading;