import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Practice from "../views/Practice/Practice"
const PracticePage = () => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Our Practice" url="https://www.kwazi.in/practice" />
    <Practice></Practice>
  </Layout>
)

export default PracticePage
